.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.upload {
  border: 1px dashed #ccc;
  max-width: 300px;
  margin: 20px auto;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.upload-icon {
  width: 50px;
  height: 50px;
  color: #eee;
}

.active .upload-icon {
  color: #fff;
}

.license {
  font-size: 20px;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
  width: 350px;
  padding: 5px 10px;
  text-align: center;
}

.license::placeholder {
  font-size: 20px;
}


.upload.uploaded {
  color: green;
  border-color: green;

}

.upload.active {
  border-color: #2be;
  background: #2be;
  color: #fff;
}

.submit {
  margin-top: 10px;
  padding: 20px;
  border: 2px solid #2be;
  color: #2be;
  font-size: 20px;
  font-weight: 600;
}

.submit:hover {
  cursor: pointer;
}

.submit[disabled] {
  color: #ddd;
  border-color: #ddd;
  cursor: not-allowed;
}
